<template>
  <div>
    <div class="navigation-bottom z-50">
        <router-link :to="{name : 'PostalCode'  }" class="add">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.799" height="19.799" viewBox="0 0 19.799 19.799">
            <path id="ic_close_24px"
                  d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                  transform="translate(9.899) rotate(45)" fill="#d9b8df"/>
          </svg>
        </router-link>
      <router-link :to="{name : 'HomeRealEstate'}" :class="[isActiveHome ? 'active' : '']">
          <svg xmlns="http://www.w3.org/2000/svg" width="22.045" height="18.738" viewBox="0 0 22.045 18.738">
            <path id="ic_home_24px"
                  d="M10.818,21.738V15.125h4.409v6.614h5.511V12.92h3.307L13.023,3,2,12.92H5.307v8.818Z"
                  transform="translate(-2 -3)" fill="#d9b8df"/>
          </svg>
        </router-link>
      <router-link :to="{name : 'LocationRealEstate'}" class="location" :class="[isActiveLocation ? 'active' : '']">
          <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="17.857" viewBox="0 0 12.5 17.857">
            <path id="ic_place_24px"
                  d="M11.25,2A6.245,6.245,0,0,0,5,8.25c0,4.688,6.25,11.607,6.25,11.607S17.5,12.937,17.5,8.25A6.245,6.245,0,0,0,11.25,2Zm0,8.482A2.232,2.232,0,1,1,13.482,8.25,2.233,2.233,0,0,1,11.25,10.482Z"
                  transform="translate(-5 -2)" fill="#d9b8df"/>
          </svg>
      </router-link>
      <router-link :to="{name : 'ProfileRealEstate'}" class="profile" :class="[isActiveProfile ? 'active' : '']">
          <svg id="ic_record_voice_over_24px" xmlns="http://www.w3.org/2000/svg" width="14.687" height="15.834"
               viewBox="0 0 14.687 15.834">
            <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.672" cy="3.672" r="3.672" transform="translate(3.672)"
                    fill="#d9b8df"/>
            <path id="Path_3" data-name="Path 3"
                  d="M8.344,12.408C5.893,12.408,1,13.638,1,16.08v1.836H15.687V16.08C15.687,13.638,10.794,12.408,8.344,12.408Z"
                  transform="translate(-1 -2.082)" fill="#d9b8df"/>
          </svg>
        </router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: "RealEstateButton",
  data(){
    return{
      isActiveHome : false,
      isActiveLocation : false,
      isActiveProfile : false,
      activeItem : this.$route.name
    }
  },
  mounted() {
    if (this.activeItem === 'HomeRealEstate'){
        this.isActiveHome = true
    }else if(this.activeItem === 'LocationRealEstate'){
      this.isActiveLocation = true
    }else if (this.activeItem === 'ProfileRealEstate'){
      this.isActiveProfile = true
    }
  }
}
</script>

<style scoped>
.navigation-bottom >>> a.active.location::after{
  right: 4px;
}
.navigation-bottom >>> a.active.profile::after{
  right: 5px;
}
</style>